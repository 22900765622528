.auth-container {
  background-image: linear-gradient(
    to bottom,
    #d9e8ef,
    #ddebf0,
    #e1edf2,
    #e6f0f3,
    #eaf2f5
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
